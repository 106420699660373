<template>
  <div>
    <v-select
      v-model="filter"
      dense
      hide-details
      :items="computedFilterOptions"
      outlined
      label="Filter by Contact"
      color="primary"
      :loading="loading"
    />
  </div>
</template>

<script>
import { tenantService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import mutationTypes from '@/store/mutation-types'

export default {
  data: function () {
    return {
      loading: false,
      filterOptions: [{ text: 'All Work', value: 0 }],
      customerContacts: [],
      unsubscribe: null
    }
  },

  computed: {
    filter: {
      get () {
        return this.$store.getters.filterUserNowwId()
      },
      set (value) {
        this.$store.dispatch('setFilterUserNowwId', value)
      }
    },
    computedFilterOptions () {
      if (this.$store.getters.isSimulatedAsCustomer()) {
        return [
          ...this.filterOptions,
          ...this.customerContacts.filter(
            x => x.contactNowwId !== this.$store.getters['auth/getUserNowwId']()
          )
        ]
      } else {
        return [
          ...this.filterOptions,
          { text: 'My Work', value: this.$store.getters['auth/getUserNowwId']() },
          ...this.customerContacts.filter(
            x => x.contactNowwId !== this.$store.getters['auth/getUserNowwId']()
          )
        ]
      }
    }
  },

  created () {
    this.loadCustomerContacts()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.$store.dispatch('setFilterUserNowwId', 0)
        this.loadCustomerContacts()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  methods: {
    loadCustomerContacts () {
      var crmId = null
      if (!this.$store.getters['auth/hasClaimKV'](claims.MMS_TEBT)) {
        crmId = this.$store.getters['auth/getTenantCrmId']()
      } else {
        crmId = this.$store.getters.viewAsCustomer()
      }
      if (this.$store.getters['isViewAllCustomers']()) {
        this.customerContacts = []
        return
      }
      this.loading = true
      tenantService
        .getContactsByTenantCrm(crmId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.customerContacts = []
          } else {
            this.customerContacts = resp.map(function (val, index) {
              return {
                text: val.name,
                value: val.contactNowwId,
                crmId: val.crmContactId
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
